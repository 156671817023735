import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const workbenchRoutes: CustomRouteRecordRaw = {
  path: 'workbench',
  name: 'workbench',
  component: () => import('@/views/workbench/workbench.vue'),
  meta: {
    title: '工作台',
    iconfont: 'icon-computer-line',
  },
  // children: [
  //   // 项目列表
  //   {
  //     path: 'index',
  //     name: 'workbenchIndex',
  //     component: () => import('@/views/workbench/workbench.vue'),
  //     meta: {
  //       title: '工作台',
  //     },
  //   },
  // ],
}
