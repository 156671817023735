import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const iqRoutes: CustomRouteRecordRaw = {
  path: 'iq',
  name: 'iq',
  component: EmptyLayout,
  meta: {
    title: 'IQ测评',
    iconfont: 'icon-user-heart-line',
    requiredPermission: 'IQ_TEST'
  },
  children: [
    {
      path: 'iq-test-pay',
      name: 'iqTestPay',
      component: () => import('@/views/iq/iq-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'IQ_TEST_PAY'
      },
    },
    {
      path: 'iq-pay-log',
      name: 'iqPayLog',
      component: () => import('@/views/iq/iq-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'IQ_PAY_LOG'
      },
    },
    {
      path: 'iq-test-list',
      name: 'iqTestList',
      component: () => import('@/views/iq/iq-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'IQ_TEST_LIST'
      },
    },
    {
      path: 'iq-test-report',
      name: 'iq-test-report',
      component: () => import('@/views/iq/iq-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'IQ_TEST_REPORT'
      },
    },
  ],
}