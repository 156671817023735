import { ExtractPropTypes } from 'vue'
import { ButtonProps } from 'ant-design-vue/es'
import { authApi } from '@/api/auth'
export const validCodeCompProps = {
  btnText: {
    type: String,
    default: '获取验证码'
  },
  validTimeSec: {
    type: Number,
    default: 60
  },
  mobile: {
    type: String,
    default: ''
  },
  getValidCodeImgApi: {
    type: Function,
    default: authApi.getCaptchaPic
  },
  validCodeApiResImgField: {
    type: String,
    default: 'img'
  },
  validCodeApiResKeyField: {
    type: String,
    default: 'key'
  },
  getCaptchaApi: {
    type: Function,
    default: authApi.getCaptcha
  }
}

export type ValidCodeCompProps = Partial<ExtractPropTypes<typeof validCodeCompProps>> & ButtonProps