import { request } from '@/utils/request'
const sqApi = {

  // 测试套餐
  getGoodsList: params => request.get('/SQ/sqTests/currentTest', params),

  // 主订单列表，下单记录列表
  getSqOrderList: params => request.post('/SQ/sqOrders/index', params),

  // 购买商品,创建主订单
  bugGoods: params => request.post('/SQ/SqOrders/store', params),
  // 创建子订单
  sqSubOrder: params => request.post('/SQ/sqSubOrders/store', params),

  // 测评记录，也是下单记录里面详情接口，详情的时候需要传下单的id
  getSqAnswererList: params => request.post('/SQ/sqAnswerers/index', params),

  // 批量生成报告
  batchGenerateReport: params => request.post('/SQ/sqAnswerers/batchGenerateReport', params),
  // 生成报告
  generateReport: params => request.post('/SQ/sqAnswerers/generateReport', params),

  // 导出报告
  exportReport: params => request.post('/SQ/sqAnswerers/exportReport', params),

  // 报告列表
  sqTestReports: params => request.get('/SQ/sqTestReports/index', params),

  // 生成二维码
  createQrCode: params => request.post('/order/orders/qrcode', params),

  // 短信链接方式导入检测
  sqImportAnswerersCheck: params => request.post(
    '/SQ/sqAnswerers/importAnswerersCheck', params,
    {
      returnRes: true,
      disableThrottling: true,
      timeout: 500 * 1000
    }
  ),

  // 测评记录统计
  getAnswererStatistic: params => request.post('/SQ/sqAnswerers/answererStatistic', params),
}
export { sqApi }