import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const agencyCenterRoutes: CustomRouteRecordRaw = {
  path: 'agency-center',
  name: 'agency-center',
  component: EmptyLayout,
  meta: {
    title: '代理中心',
    iconfont: 'icon-git-merge-line',
    requiredPermission: 'AGENCY_CENTER'
  },
  children: [
    {
      path: 'agency-center-pay',
      name: 'agencyCenterPay',
      component: () => import('@/views/agency-center/agency-center-pay.vue'),
      meta: {
        title: '代理下单',
        requiredPermission: 'AGENCY_CENTER_PAY'
      },
    },
    {
      path: 'agency-order-record',
      name: 'agencyOrderRecord',
      component: () => import('@/views/agency-center/agency-order-record.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'AGENCY_ORDER_RECORD'
      },
    },
    {
      path: 'agency-market-statis',
      name: 'agencyMarketStatis',
      component: () => import('@/views/agency-center/agency-market-statis.vue'),
      meta: {
        title: '营销统计',
        requiredPermission: 'AGENCY_MARKET_STATIS'
      },
    },
    {
      path: 'agency-customer-data',
      name: 'agencyCustomerData',
      component: () => import('@/views/agency-center/agency-customer-data.vue'),
      meta: {
        title: '客户数据',
        requiredPermission: 'AGENCY_CUSTOMER_DATA'
      },
    },
    {
      path: 'agency-performance-statis',
      name: 'agencyPerformanceStatis',
      component: () => import('@/views/agency-center/agency-performance-statis.vue'),
      meta: {
        title: '业绩统计',
        requiredPermission: 'AGENCY_PERFORMANCE_STATIS'
      },
    },
  ],
}