import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const myRoutes: CustomRouteRecordRaw = {
  path: 'my',
  name: 'my',
  component: () => import('@/views/my/my-info.vue'),
  meta: {
    title: '我的信息',
    iconfont: 'icon-user-2-line',
    requiredPermission: 'MY'
  },
}
