<style lang="less">
  .basic_from_label_wrap {
    display: inline-block;
    margin-left: 6px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      color: @primary-color;
    }

    &__wrap {
      p {
        margin-bottom: 0;
      }
    }
  }

</style>
<script lang="tsx">
import { defineComponent, computed, unref, Slots } from 'vue'
// eslint-disable-next-line no-duplicate-imports
import type { CSSProperties, PropType, VNodeChild } from 'vue'
import { Tooltip } from 'ant-design-vue'
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { isString, isArray, isFunction } from 'lodash-es'

const props = {

  /**
     * Help text max-width
     * @default: 600px
     */
  maxWidth: {
    type: String,
    default: '600px'
  },

  /**
     * Whether to display the serial number
     * @default: false
     */
  showIndex: { type: Boolean },

  /**
     * Help text font color
     * @default: #ffffff
     */
  color: {
    type: String,
    default: '#ffffff'
  },

  /**
     * Help text font size
     * @default: 14px
     */
  fontSize: {
    type: String,
    default: '14px'
  },

  /**
     * Help text list
     */
  placement: {
    type: String,
    default: 'right'
  },

  /**
     * Help text list
     */
  text: {
    type: [Array, String, Object] as PropType<string[] | string | VNodeChild | JSX.Element>,
  },
}

type RenderOpts = {
  disabled: boolean;
  [key: string]: any;
}

export default defineComponent({
  name: 'BasicHelp',
  components: { Tooltip },
  props,
  setup(props, { slots }) {
    const getTooltipStyle = computed((): CSSProperties => ({
      color: props.color,
      fontSize: props.fontSize
    }))

    const getOverlayStyle = computed((): CSSProperties => ({ maxWidth: props.maxWidth }))

    function getPopupContainer(node?: HTMLElement): HTMLElement {
      return (node?.parentNode as HTMLElement) ?? document.body
    }

    function getSlot(slots: Slots, slot = 'default', data?: any, opts?: RenderOpts) {
      if (!slots || !Reflect.has(slots, slot)) {
        return null
      }
      if (!isFunction(slots[slot])) {
        console.error(`${slot} is not a function!`)
        return null
      }
      const slotFn = slots[slot]
      if (!slotFn) {
        return null
      }
      const params = {
        ...data,
        ...opts
      }
      return slotFn(params)
    }

    function renderTitle() {
      const textList = props.text
      if (isString(textList)) {
        return textList
      }
      if (isArray(textList)) {
        return textList.map((text, index) => (
          <p key={text}>
            {props.showIndex ? `${index + 1}. ` : ''}
            {text}
          </p>
        ))
      }
      return <div>{textList}</div>
    }

    return () => (
      <Tooltip
        overlayClassName="basic_from_label_wrap"
        title={<div style={unref(getTooltipStyle)}>{renderTitle()}</div>}
        autoAdjustOverflow={true}
        overlayStyle={unref(getOverlayStyle)}
        placement={props.placement as 'right'}
        getPopupContainer={() => getPopupContainer()}
      >
        <span>{getSlot(slots) || <InfoCircleOutlined class="text-blue-500" />}</span>
      </Tooltip>
    )
  },
})
</script>
