import { request } from '@/utils/request'

/** 用户接口 */
const userApi = {
  // 添加用户
  addUser: params => request.post('users', params),

  // 修改用户手机
  updateUserMobile: params => request.put('user/users/mobile', params),

  // 修改用户邮箱
  updateUserEmail: params => request.put('user/users/email', params),

  // 账户注销校验
  unsubscribeAccountVerify: () => request.get('user/users/unsubscribeAccountVerify'),

  // 账户注销
  unsubscribe: params => request.post('user/users/unsubscribe', params)
}

export { userApi }