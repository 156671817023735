<!-- 纯展示组件，仅用于数据展示，不做操作，以便外部自定义内容 -->

<template>
  <div
    class="pure-display"
    v-bind="attrs"
  >
    <slot />
  </div>
</template>
<script lang="tsx" setup>
const attrs = useAttrs()

</script>
