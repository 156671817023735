import type { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
import { useSiteConfig } from '@/hooks/site-config'
import BackendLayout from '@/views/layouts/backend-layout.vue'
import { exampleRoutes } from './example'
import { workbenchRoutes } from './workbench'
import { paymentRoutes } from './payment'
import { billRoutes } from './bill'
import { myRoutes } from './my'
import { accountRoutes } from './account'
import { invoiceRoutes } from './invoice'
import { contractRoutes } from './contract'
import { notificationRoutes } from './notification'
import { mentalRoutes } from './mental'
import { iqRoutes } from './iq'
import { eqRoutes } from './eq'
import { jqRoutes } from './jq'
import { sqRoutes } from './sq'
import { mbtiRoutes } from './mbti'
import { discRoutes } from './disc'
import { agencyCenterRoutes } from './agency-center'
import { HelpRoutes } from './help'
const { backendPrefix } = useSiteConfig

export const appRoutes: CustomRouteRecordRaw[] = [
  workbenchRoutes,
  billRoutes,
  mentalRoutes,
  iqRoutes,
  eqRoutes,
  jqRoutes,
  sqRoutes,
  mbtiRoutes,
  discRoutes,
  accountRoutes,
  contractRoutes,
  invoiceRoutes,
  paymentRoutes,
  HelpRoutes,
  notificationRoutes,
  myRoutes,
  agencyCenterRoutes,
]


const routes: CustomRouteRecordRaw[] = [
  {
    path: '/',
    name: 'OffcailWeb',
    component: () => import('@/views/offcial-web.vue'),
    meta: {
      title: '官网',
      noNeedAuth: true
    }
  },
  {
    path: `${backendPrefix}/login`,
    name: 'login',
    component: () => import('@/views/auth/login/login.vue'),
    meta: {
      title: '账号登录',
      noNeedAuth: true,
    },
  },
  {
    path: backendPrefix,
    name: 'root',
    component: BackendLayout,
    redirect: { name: 'workbench' },
    children: appRoutes,
  },
  {
    path: '/:patchMatch(.*)*',
    name: 'notFound',
    redirect: { name: 'root' },
    // component: () => import('@/views/404.vue'),
    // meta: {
    //   title: '页面不存在',
    //   noNeedAuth: true,
    // },
  },
]


// 获取父路由的名字
function setParentName(routeList, parentNameList) {
  routeList.forEach(route => {
    route.meta = route.meta || {}
    route.meta.parentName = parentNameList
    route.children?.length
      && setParentName(route.children, [...parentNameList, route.name])
  })
}
setParentName(routes, [])
export default routes


export function redirectF(children): RouteLocationRaw {


  for (let i = 0; i < children.length; i++) {
    console.log(children[i])
    // if (flatRoutes.includes(children[i].name)) {
    //   return { name: children[i].name }
    // }
  }
  return { name: 'projectList' }
}