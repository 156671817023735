
/**
 *
 * @param num 生成的随机字母个数
 * @returns 随机的6位字母字符串数组
 */
export function getRandomName(num: number):string[] {
  let resArray: string[] = []
  for (let j = 0; j < num; j++) {
    let code = ''
    for (let i = 0; i < 3; i++) {
      // 通过生成ASCII码表A-Z a-z对应的ASCII值转成对应字母拼接成随机字母
      code += String.fromCharCode(Math.floor((Math.random() * 26) + 65)) + String.fromCharCode(Math.floor((Math.random() * 26) + 97))
    }
    resArray.push(code)
  }
  return resArray
}