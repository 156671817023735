import { defineStore } from 'pinia'
import { globalApi } from '@/api/global'
import { isNumber, isString } from 'lodash-es'
import { localReference } from '@/types/local-reference'
import { Stoppable, useTimeoutFn } from '@vueuse/core'
import { notificationApi } from '@/api/notification'
const referenceColorMap = {
  green: '#00B42A',
  orange: '#FF9A2E',
  red: '#F53F3F',
}

interface GlobalState {

  /** 枚举类型 */
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;

  referenceColorMap: typeof referenceColorMap;

  notifications: Model.Notification[];
  dots: number;
}
let timer: Nullable<Stoppable>

/** app 全局信息 */
export const useAppStore = defineStore('App', {
  state: (): GlobalState => ({
    reference: null,

    /** reference 中配置的颜色转换器 */
    referenceColorMap,

    notifications: [],
    dots: 0
  }),

  actions: {

    /** 获取枚举值详情 */
    getReferenceLabel(
      referenceKey: Reference.ReferenceKey,
      value: number
    ): string {
      const referenceItem = this.getReferenceItems(referenceKey)
      if (!isNumber(value) && !isString(value)) {
        return ''
      }
      return (
        referenceItem.find(item => item.value.toString() === value.toString())
          ?.label || ''
      )
    },

    /** 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /** 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /** 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /** 重置枚举值 */
    async refreshReference() {
      this.reference = await globalApi.getReference()
      this.reference = Object.assign(localReference, this.reference)

      /** 设置枚举颜色值 */

      const auditStatusEnum = this.getReferenceConstantMap('auditStatusEnum')
      auditStatusEnum.PASS.color = 'green'
      auditStatusEnum.WAIT.color = 'orange'
      auditStatusEnum.REJECT.color = 'red'

      const enableEnum = this.getReferenceConstantMap('enableEnum')
      enableEnum.ENABLE.color = 'green'
      enableEnum.DISABLE.color = 'red'

      const inviteStatusEnum = this.getReferenceConstantMap('inviteStatusEnum')
      inviteStatusEnum.INVITE_CONFIRM.color = 'green'
      inviteStatusEnum.INVITE_WAITING.color = 'red'

      const paidStatusEnum = this.getReferenceConstantMap('paidStatusEnum')
      paidStatusEnum.UNPAID.color = 'blue'
      paidStatusEnum.PAID.color = 'green'
      paidStatusEnum.FAILED.color = 'red'
      paidStatusEnum.REFUNDING.color = 'blue'
      paidStatusEnum.REFUND.color = 'red'
      paidStatusEnum.REFUND_FAIL.color = 'red'

      const backSystemLevelEnum = this.getReferenceConstantMap('backSystemLevelEnum')
      backSystemLevelEnum.BLUE.color = '#5ca3ef'
      backSystemLevelEnum.BLUE.bgColor = 'blue'
      backSystemLevelEnum.RED.color = '#e717a'
      backSystemLevelEnum.RED.bgColor = 'red'
      backSystemLevelEnum.GREEN.color = '#74cf4e'
      backSystemLevelEnum.GREEN.bgColor = 'green'
      backSystemLevelEnum.YELLOW.color = '#d9b400'
      backSystemLevelEnum.YELLOW.bgColor = 'yellow'

      const backSystemStatusEnum = this.getReferenceConstantMap('backSystemStatusEnum')
      backSystemStatusEnum.NOT_STARTED.color = '#c1c1c1'
      backSystemStatusEnum.AUTHING.color = 'blue'
      backSystemStatusEnum.DONE.color = 'green'
      backSystemStatusEnum.DOING.color = 'orange'
      backSystemStatusEnum.CANCEL.color = 'red'

      const orderInvoiceTypeEnum = this.getReferenceConstantMap('orderInvoiceTypeEnum')
      orderInvoiceTypeEnum.ORDER_INVOICE_FINISH.color = 'green'
      orderInvoiceTypeEnum.ORDER_INVOICE_FALLBACK.color = 'red'
      orderInvoiceTypeEnum.ORDER_INVOICE_WAITING.color = 'orange'
      orderInvoiceTypeEnum.ORDER_INVOICE_READY.color = 'orange'


      const contractSignStatusEnum = this.getReferenceConstantMap('contractSignStatusEnum')
      contractSignStatusEnum.DRAFT.color = 'red'
      contractSignStatusEnum.SIGNED.color = 'green'
      contractSignStatusEnum.SIGNING.color = 'orange'
      contractSignStatusEnum.CANCEL.color = '#c1c1c1'

      const paymentIsOuStatusEnum = this.getReferenceConstantMap('paymentIsOutLocalEnum')
      paymentIsOuStatusEnum.ENTRY.color = 'green'
      paymentIsOuStatusEnum.OUT.color = 'green'

      const answerStatusEnum = this.getReferenceConstantMap('answerStatusEnum')
      answerStatusEnum.CANCEL.color = 'red'
      answerStatusEnum.FINISHED.color = 'green'
      answerStatusEnum.ANSWERED.color = 'green'
      answerStatusEnum.UNDERWAY.color = 'blue'
      answerStatusEnum.NOT_START.color = '#c1c1c1'

      const invoiceStatusEnum = this.getReferenceConstantMap('invoiceStatusEnum')
      invoiceStatusEnum.INVOICE_MAKE_FAIL.color = 'red'
      invoiceStatusEnum.INVOICE_MAKE_PASS.color = 'green'
      invoiceStatusEnum.INVOICE_MAKING.color = 'orange'

      const agencyOrderStatusEnum = this.getReferenceConstantMap('agencyOrderStatusEnum')
      agencyOrderStatusEnum.FINISHED.color = 'green'
      agencyOrderStatusEnum.TESTING.color = 'orange'
      agencyOrderStatusEnum.INVALID.color = 'red'
    },


    async refreshTimerData(loop?: boolean) {
      this.notifications = await notificationApi.getNotificationList({ isRead: 0 })
      this.dots = this.notifications.length

      if (loop && import.meta.env.PROD) {
        timer = useTimeoutFn(() => this.refreshTimerData(true), 5000)
      }
    },

    async stopTimer() {
      await timer?.stop()
      timer = null
    },
  },
})
