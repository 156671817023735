import { request } from '@/utils/request'

// 企业
const companyApi = {

  /** 【】获取企业列表 */
  getCompanyList: params => request.get('company/companies/index', params),

  /** 【】更新企业 */
  updateCompany: (id, params) => request.put(`company/companies/update/${id}`, params),

  /** 【】企业审批通过 */
  pass: params => request.post('company/companies/pass', params),

  /** 【】企业审批拒绝 */
  reject: params => request.post('company/companies/reject', params),

}

export { companyApi }


