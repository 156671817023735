import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const jqRoutes: CustomRouteRecordRaw = {
  path: 'jq',
  name: 'jq',
  component: EmptyLayout,
  meta: {
    title: '职业兴趣测试',
    iconfont: 'icon-palette-fill',
    requiredPermission: 'JQ_TEST'
  },
  children: [
    {
      path: 'jq-test-pay',
      name: 'jqTestPay',
      component: () => import('@/views/jq/jq-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'JQ_TEST_PAY'
      },
    },
    {
      path: 'jq-pay-log',
      name: 'jqPayLog',
      component: () => import('@/views/jq/jq-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'JQ_PAY_LOG'
      },
    },
    {
      path: 'jq-test-list',
      name: 'jqTestList',
      component: () => import('@/views/jq/jq-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'JQ_TEST_LIST'
      },
    },
    {
      path: 'jq-test-report',
      name: 'jq-test-report',
      component: () => import('@/views/jq/jq-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'JQ_TEST_REPORT'
      },
    },
  ],
}