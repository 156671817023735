import { request } from '@/utils/request'
const mentalApi = {

  // 商品列表
  getGoodsList: params => request.get('mental/mentalTests/index', params),

  // 购买商品
  bugGoods: params => request.post('mental/mentalOrders/store', params),

  // 创建子单
  mentalSubOrder: params => request.post('/mental/mentalSubOrders/store', params),

  // 下单记录列表
  getMentalOrderList: params => request.get('/mental/mentalOrders/index', params),

  // 获取二维码
  getQRcode: params => request.get('/mental/mentalOrders/mentalOrderQrcode', params),

  // 测评记录
  getMentalTestList: params => request.get('/mental/mentalAnswerers/index', params),

  // 测评统计
  getStatisticAnswerStatusCount: () => request.get('mental/mentalAnswerers/statisticAnswerStatusCount'),

  // 报告列表
  getMentalTestReportsList: params => request.get('mental/mentalTestReports/index', params),

  // 导出评测
  exportMentalReport: params => request.post('/mental/mentalAnswerers/exportReport', params),

  // 导入检测
  importAnswerersCheck: params => request.post(
    '/mental/mentalAnswerers/importAnswerersCheck', params,
    {
      returnRes: true,
      disableThrottling: true,
      timeout: 500 * 1000
    }
  ),

  // 批量生成报告
  batchGenerateReport: params => request.post('/mental/mentalAnswerers/batchGenerateReport', params),
  // 生成报告
  generateReport: params => request.post('/mental/mentalAnswerers/generateReport', params),
}
export { mentalApi }