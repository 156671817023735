import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const billRoutes: CustomRouteRecordRaw = {
  path: 'bill',
  name: 'bill',
  component: EmptyLayout,
  meta: {
    title: '背调管理',
    iconfont: 'icon-user-settings-line',
    requiredPermission: 'BILL'
  },
  children: [
    {
      path: 'pay',
      name: 'pay',
      component: () => import('@/views/bill/pay.vue'),
      meta: {
        title: '背调下单',
        requiredPermission: 'PAY'
      },
    },
    {
      path: 'bill-list',
      name: 'billList',
      component: () => import('@/views/bill/bill-list.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'BILL_LIST'
      },
    },
    {
      path: 'back-check-log',
      name: 'backCheckLog',
      component: () => import('@/views/bill/back-check-log.vue'),
      meta: {
        title: '背调记录',
        requiredPermission: 'BACK_CHECK_LOG'
      },
    },
    {
      path: 'back-report-list',
      name: 'backReportList',
      component: () => import('@/views/bill/report-list.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'BACK_REPORT_LIST'
      }
    },
  ],
}