<style lang="less" scoped>
.content:parent {
  padding: 12px 6px !important;
}
</style>

<template>
  <div class="relative">
    <!-- <span
      v-if="notificaitons.length"
      class="bg-red-500 w-[18px] z-0 h-[18px] text-white flex items-center justify-center text-[10px] absolute top-[4px] right-[4px] rounded-full p-[4px]"
    >
      {{ notificaitons.length > 100 ? '99+' : notificaitons.length }}2
    </span> -->
    <div class="z-10 flex items-center h-full">
      <a-dropdown placement="bottom">
        <div
          class="cursor-pointer w-[50px] h-[50px] flex justify-center items-center rounded text-gray-400 hover:text-blue-400 hover:bg-gray-100"
        >
          <a-badge
            :count="notificaitons.length"
            :overflow-count="99"
            size="small"
          >
            <BasicIcon
              name="icon-notification-2-line"
              class="text-xl"
            />
          </a-badge>
        </div>

        <template #overlay>
          <div class="bg-white shadow-lg">
            <div
              v-if="notificaitons.length > 0"
              style="width: 320px;"
              class="content"
            >
              <div
                class="flex justify-between px-4 py-3 font-bold"
                style="border-bottom: solid 1px #EEEEEE;"
              >
                <div>
                  共 <span class="text-base text-red-600">{{ notificaitons.length }}</span> 条未读通知
                </div>
                <div
                  class="cursor-pointer text-primaryColor-default"
                  @click="allRead"
                >
                  一键已读
                </div>
              </div>
              <div
                v-for="item in notificaitons.slice(0, 5)"
                :key="item.id"
                class="relative px-4 py-2 cursor-pointer group hover:bg-[#F5F7F9]"
                style="border-bottom: solid 1px #EEEEEE;"
              >
                <div class="flex items-center w-full">
                  <div
                    class="flex items-center justify-center bg-primaryColor-default rounded-[40px] w-[40px] h-[40px] mr-4 mt-1"
                  >
                    <basic-icon
                      name="icon-copper-coin-line"
                      class="mt-1 text-xl text-white"
                    />
                  </div>
                  <div class="flex-1">
                    <div class="font-bold">
                      {{ item.name }}
                    </div>
                    <div class="text-gray-400">
                      {{ item.content }}
                    </div>
                    <div class="flex justify-between">
                      <div class="text-xs text-gray-200">
                        {{ dateFromNow(item.createdAt) }}
                      </div>
                      <div
                        class="invisible text-xs text-primaryColor-default group-hover:visible hover:underline"
                        @click="() => handleRead(item.id)"
                      >
                        标记已读
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-2 text-center">
                <router-link :to="{ name: 'notification' }">
                  查看更多...
                </router-link>
              </div>
            </div>
            <div
              v-else
              class="p-2 px-8 text-center content"
            >
              暂无未读消息
            </div>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { notificationApi } from '@/api/notification'
import { computed } from 'vue'
import { dateFromNow } from '@/utils/date'
import { useAppStore } from '@/store/modules/app'
import { Model } from 'echarts'
import { Modal } from 'ant-design-vue'
// const notificaitons = ref([])
const appStore = useAppStore()

const notificaitons = computed<Model.Notification[]>(() => appStore.notifications)

async function handleRead(id: number) {
  await notificationApi.readMessage({ ids: [id] })
  appStore.refreshTimerData()
}
async function allRead() {
  await notificationApi.readMessage({ allRead: true })
  await appStore.refreshTimerData()
}
</script>
