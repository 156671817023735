import { request } from '@/utils/request'
const iqApi = {

  // 测试套餐
  getGoodsList: params => request.get('/IQ/iqTests/currentTest', params),

  // 主订单列表，下单记录列表
  getIqOrderList: params => request.post('/IQ/iqOrders/index', params),

  // 购买商品,创建主订单
  bugGoods: params => request.post('/IQ/iqOrders/store', params),
  // 创建子订单
  iqSubOrder: params => request.post('/IQ/iqSubOrders/store', params),

  // 测评记录，也是下单记录里面详情接口，详情的时候需要传下单的id
  getIqAnswererList: params => request.post('/IQ/iqAnswerers/index', params),

  // 批量生成报告
  batchGenerateReport: params => request.post('/IQ/iqAnswerers/batchGenerateReport', params),
  // 生成报告
  generateReport: params => request.post('/IQ/iqAnswerers/generateReport', params),

  // 导出报告
  exportReport: params => request.post('/IQ/iqAnswerers/exportReport', params),

  // 报告列表
  iqTestReports: params => request.get('/IQ/iqTestReports/index', params),

  // 生成二维码
  createQrCode: params => request.post('/order/orders/qrcode', params),

  // 短信链接方式导入检测
  iqImportAnswerersCheck: params => request.post(
    '/IQ/iqAnswerers/importAnswerersCheck', params,
    {
      returnRes: true,
      disableThrottling: true,
      timeout: 500 * 1000
    }
  ),

  // 测评记录统计
  getAnswererStatistic: params => request.post('/IQ/iqAnswerers/answererStatistic', params),
}
export { iqApi }