import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const contractRoutes: CustomRouteRecordRaw = {
  path: 'contract',
  name: 'contract',
  component: EmptyLayout,
  meta: {
    title: '合同管理',
    iconfont: 'icon-article-line1',
    requiredPermission: 'CONTRACT'
  },
  children: [
    {
      path: 'contract-list',
      name: 'contractList',
      component: () => import('@/views/contract/contract-list.vue'),
      meta: {
        title: '签署列表',
        requiredPermission: 'CONTRACT_LIST'
      },
    }
  ],
}