import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const discRoutes: CustomRouteRecordRaw = {
  path: 'disc',
  name: 'disc',
  component: EmptyLayout,
  meta: {
    title: 'DISC性格测试',
    iconfont: 'icon-user-search-line',
    requiredPermission: 'DISC_TEST'
  },
  children: [
    {
      path: 'disc-test-pay',
      name: 'discTestPay',
      component: () => import('@/views/disc/disc-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'DISC_TEST_PAY'
      },
    },
    {
      path: 'disc-pay-log',
      name: 'discPayLog',
      component: () => import('@/views/disc/disc-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'DISC_PAY_LOG'
      },
    },
    {
      path: 'disc-test-list',
      name: 'discTestList',
      component: () => import('@/views/disc/disc-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'DISC_TEST_LIST'
      },
    },
    {
      path: 'disc-test-report',
      name: 'disc-test-report',
      component: () => import('@/views/disc/disc-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'DISC_TEST_REPORT'
      },
    },
  ],
}