import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const mentalRoutes: CustomRouteRecordRaw = {
  path: 'mental',
  name: 'mental',
  component: EmptyLayout,
  meta: {
    title: '九型人格测试',
    iconfont: 'icon-user-search-line',
    requiredPermission: 'MENTAL_TEST'
  },
  children: [
    {
      path: 'mental-test-pay',
      name: 'mentalTestPay',
      component: () => import('@/views/mental/mental-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'MENTAL_TEST_PAY'
      },
    },
    {
      path: 'pay-log',
      name: 'payLog',
      component: () => import('@/views/mental/pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'PAY_LOG'
      },
    },
    {
      path: 'mental-test-list',
      name: 'mentalTestList',
      component: () => import('@/views/mental/mental-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'MENTAL_TEST_LIST'
      },
    },
    {
      path: 'mental-test-report',
      name: 'mental-test-report',
      component: () => import('@/views/mental/mental-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'MENTAL_TEST_REPORT'
      },
    },
  ],
}