import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const HelpRoutes: CustomRouteRecordRaw = {
  path: 'help',
  name: 'help',
  component: () => import('@/views/help/article.vue'),
  meta: {
    title: '帮助中心',
    iconfont: 'icon-bookmark-3-fill',
    requiredPermission: 'HELP_CENTER'
  },
}