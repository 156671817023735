import { PropType, ExtractPropTypes } from 'vue'
import { Required } from 'utility-types'
import type { SelectProps } from 'ant-design-vue/es/select'

export const searchApiSelectProps = {
  value: [Number, String, Array] as PropType<any>,
  api: Function as PropType<Fn>,
  immediate: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  filter: {
    type: Object as PropType<Recordable>,
  },
  valueField: {
    type: [String, Function] as PropType<string | Fn>,
    default: 'value',
  },
  labelField: {
    type: [String, Function] as PropType<string | Fn>,
    default: 'label',
  },
  resultField: {
    type: String as PropType<string>,
    default: 'items',
  },
  zeroToUndefined: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  numberToString: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showSearch: Boolean as PropType<boolean>,
}

export type SearchApiSelectProps = Partial<ExtractPropTypes<typeof searchApiSelectProps>> & SelectProps