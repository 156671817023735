import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const eqRoutes: CustomRouteRecordRaw = {
  path: 'eq',
  name: 'eq',
  component: EmptyLayout,
  meta: {
    title: 'EQ测评',
    iconfont: 'icon-user-star-line',
    requiredPermission: 'EQ_TEST'
  },
  children: [
    {
      path: 'eq-test-pay',
      name: 'eqTestPay',
      component: () => import('@/views/eq/eq-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'EQ_TEST_PAY'
      },
    },
    {
      path: 'eq-pay-log',
      name: 'eqPayLog',
      component: () => import('@/views/eq/eq-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'EQ_PAY_LOG'
      },
    },
    {
      path: 'eq-test-list',
      name: 'eqTestList',
      component: () => import('@/views/eq/eq-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'EQ_TEST_LIST'
      },
    },
    {
      path: 'eq-test-report',
      name: 'eq-test-report',
      component: () => import('@/views/eq/eq-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'EQ_TEST_REPORT'
      },
    },
  ],
}