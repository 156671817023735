import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const invoiceRoutes: CustomRouteRecordRaw = {
  path: 'invoice',
  name: 'invoice',
  component: EmptyLayout,
  meta: {
    title: '发票管理',
    iconfont: 'icon-file-list-3-line',
    requiredPermission: 'INVOICE'
  },
  children: [
    {
      path: 'invoice-apply',
      name: 'invoiceApply',
      component: () => import('@/views/invoice/invoice-apply.vue'),
      meta: {
        title: '申请开票',
        requiredPermission: 'INVOICE_APPLY'
      },
    },
    {
      path: 'invoice-list',
      name: 'invoiceList',
      component: () => import('@/views/invoice/invoice-list.vue'),
      meta: {
        title: '开票历史',
        requiredPermission: 'INVOICE_LIST'
      },
    },
    {
      path: 'invoice-header-list',
      name: 'invoiceHeaderList',
      component: () => import('@/views/invoice/invoice-header.vue'),
      meta: {
        title: '发票抬头',
        requiredPermission: 'INVOICE_HEADER'
      }
    }
  ],
}