<template>
  <a-button
    v-bind="bindAttrs"
    @click="showValidCode"
  >
    <span v-if="validateMsg.isRun">{{ validateMsg.time }}</span><span>{{ validateMsg.text }}</span>
  </a-button>
</template>

<script lang="tsx" setup>
import { computed } from 'vue'
import { validCodeCompProps } from './props'
import { omit, isFunction, get } from 'lodash-es'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { useMessage } from '@/hooks/message'
import { authApi } from '@/api/auth'
import globalModelForm from '@/hooks/global-model-form'
import { Image, Input } from 'ant-design-vue'
const props = defineProps(validCodeCompProps)

const bindAttrs = computed(() => ({
  ...omit(props, ['mobile', 'validTimeSec', 'btnText']),
}))
const state = reactive({
  validImgText: '',
  validImg: '',
  validImgKey: ''
})
let timer: Nullable<NodeJS.Timer> = null
const validateMsg = reactive({
  time: props.validTimeSec,
  text: props.btnText,
  isRun: false
})
const showValidCode = async () => {
  if (!isMobilePhone(props.mobile || '', 'zh-CN')) {
    useMessage.error('请输入正确手机号')
    return
  }
  if (timer) {
    return
  }
  if (validateMsg.isRun) {
    return
  }
  state.validImgText = ''
  await refreshCaptchaPic()
  globalModelForm.init({
    title: '验证',
    okText: '发送短信验证码',
    schemas: [
      {
        label: '',
        field: '',
        component: 'PureDisplay',
        renderComponentContent: () => (<div class="flex">
          <Image onClick={() => {
            refreshCaptchaPic()
          }} preview={false} style={{
            height: '32px',
            width: '90px',
            border: '1px solid #d9d9d9',
            cursor: 'pointer'
          }} src={state.validImg} />
          <Input placeholder="请输入验证码" style={{ marginLeft: '10px' }} value={state.validImgText} onChange={e => {
            state.validImgText = e.target.value ?? ''
          }} />
        </div>)
      },
    ],
    api: async () => {
      await getCaptchaFn()
    },
    isSuccessMsg: false
  })
}
const getCaptchaFn = async () => {
  if (!state.validImgText) {
    useMessage.error('请输入图片验证码')
    throw new Error('请输入图片验证码')
  }
  try {

    /* 获取验证码 */
    if (isFunction(props.getCaptchaApi)) {
      await props.getCaptchaApi({
        mobile: props.mobile,
        code: state.validImgText,
        key: state.validImgKey
      })
      useMessage.success('发送成功')
      validateMsg.isRun = true
      validateMsg.text = 'S后获取'
      timer = setInterval(() => {
        validateMsg.time--
        if (validateMsg.time === 0) {
          clearInterval(timer!)
          timer = null
          validateMsg.text = props.btnText
          validateMsg.time = props.validTimeSec
          validateMsg.isRun = false
        }
      }, 1000)
    }

  } catch (e) {
    // 图片验证码错误刷新
    await refreshCaptchaPic()
    throw new Error(e?.toString())
  }
}
// 刷新图片验证码
const refreshCaptchaPic = async () => {
  console.log('刷新了图片验证码')
  if (isFunction(props.getValidCodeImgApi)) {
    const res = await props.getValidCodeImgApi()
    state.validImg = get(res, props.validCodeApiResImgField)
    state.validImgKey = get(res, props.validCodeApiResKeyField)
  }
}
</script>
