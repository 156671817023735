import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const mbtiRoutes: CustomRouteRecordRaw = {
  path: 'mbti',
  name: 'mbti',
  component: EmptyLayout,
  meta: {
    title: 'MBTI性格测试',
    iconfont: 'icon-user-search-line',
    requiredPermission: 'MBTI_TEST'
  },
  children: [
    {
      path: 'mbti-test-pay',
      name: 'mbtiTestPay',
      component: () => import('@/views/mbti/mbti-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'MBTI_TEST_PAY'
      },
    },
    {
      path: 'mbti-pay-log',
      name: 'mbtiPayLog',
      component: () => import('@/views/mbti/mbti-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'MBTI_PAY_LOG'
      },
    },
    {
      path: 'mbti-test-list',
      name: 'mbtiTestList',
      component: () => import('@/views/mbti/mbti-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'MBTI_TEST_LIST'
      },
    },
    {
      path: 'mbti-test-report',
      name: 'mbti-test-report',
      component: () => import('@/views/mbti/mbti-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'MBTI_TEST_REPORT'
      },
    },
  ],
}