import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const notificationRoutes: CustomRouteRecordRaw = {
  path: 'notification',
  name: 'notification',
  component: () => import('@/views/notification/notification.vue'),
  meta: {
    title: '消息中心',
    iconfont: 'icon-notification-2-line',
    requiredPermission: 'NOTIFICATION'
  },
}
