<template>
  <a-select
    v-bind="bindAttrs"
    @dropdownVisibleChange="handleFetch"
  >
    <template
      v-if="loading"
      #suffixIcon
    >
      <loading-outlined spin />
    </template>
    <template #notFoundContent>
      <div
        v-if="isSearched"
        class="text-center"
      >
        暂无数据
      </div>
      <div
        v-else
        class="text-center"
      >
        请输入关键字搜索
      </div>
    </template>
  </a-select>
</template>
<script lang="ts" setup>
import { computed, unref, useAttrs } from 'vue'
import { get, isFunction, isArray, debounce } from 'lodash-es'
import { searchApiSelectProps } from './props'

const props = defineProps(searchApiSelectProps)
const emits = defineEmits(['blur', 'change', 'update:value', 'options-change'])
const attrs = useAttrs()
const loading = ref(false)
const options = ref<Recordable[]>([])
const v = ref()

watch(
  () => props.value,
  value => {
    if (value === 0 && props.zeroToUndefined) {
      v.value = undefined
    } else {
      v.value = value
    }
  },
  {
    immediate: true,
  },
)

onMounted(() => {
  if (props.immediate || props.value) {
    const param = !isFunction(props.valueField) ? { [props.valueField]: props.value } : undefined
    fetch(param)
  }
})

/*
watch(
  () => [props.api, props.filter],
  () => fetch(),
) */

let isFetched = false
async function handleFetch() {
  if (!isFetched) {
    if (await fetch()) {
      isFetched = true
    }
  }
}

const isSearched = ref(false)
const handleSearch = debounce(async (keyword: string) => {
  if (props.showSearch && keyword) {
    if (await fetch({ keyword })) {
      isSearched.value = true
    }
  }
}, 500)

async function fetch(param?: Recordable) {
  const { api, filter, resultField, labelField, valueField, numberToString } = props
  if (!api || !isFunction(api)) {
    return
  }

  try {
    loading.value = true
    const requestParam = {
      ...filter,
      ...param
    }
    const res = await api(requestParam)
    let optionData = isArray(res) ? res : get(res, resultField)
    options.value = optionData?.map(item => {
      const value = isFunction(valueField) ? valueField(item) : item[valueField]
      const label = isFunction(labelField) ? labelField(item) : item[labelField]
      const rawItem = item
      if (item.isDefault) {
        v.value = value
      }
      return {
        label,
        value: numberToString ? `${value}` : value,
        rawItem,
      }
    })
    emits('options-change', options.value)
    return true
  } catch (error) {
    console.warn(error)
    return false
  } finally {
    loading.value = false
  }
}

const bindAttrs = computed(() => ({
  dropdownStyle: { maxHeight: '280px' },
  optionFilterProp: 'label',
  value: unref(v),
  options: unref(options),
  onChange: (...value) => emits('change', ...value),
  'onUpdate:value': (...value) => emits('update:value', ...value),
  ...attrs,
  ...(props.showSearch ? {
    showSearch: true,
    onSearch: handleSearch
  } : {}),
}))
</script>
