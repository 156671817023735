<template>
  <div>
    <a-form-item-rest>
      <template v-if="dataSource.length === 0 && fileList.length === 0">
        <div class="flex justify-center ">
          <a-upload-dragger
            :file-list="fileList"
            :max-count="1"
            accept=".xlsx, .xls"
            :multiple="false"
            :before-upload="() => false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <basic-icon
                name="icon-upload_black_24dp"
                class="text-4xl"
              />
            </p>
            <p class="ant-upload-text">
              点击或拖拽文件到此上传
            </p>
            <p class="px-2 ant-upload-hint">
              可直接将文件拖拽到此处进行上传，支持格式: XLS、XLSX，大小不超过5M
            </p>
          </a-upload-dragger>
        </div>
        <Divider class="mt-10" />
        <div class="flex justify-between mb-2 text-red-500 ">
          上传前请确认： <span
            v-if="Boolean(props.downLoadUrl)"
            class="text-blue-500 cursor-pointer"
            @click="downloadFile(props.downLoadUrl, {}, props.excelName, 'xlsx')"
          >
            下载模板
          </span>
        </div>

        <div
          v-for="(tip, index) in props.warningTexts"
          :key="index"
          class="mb-1 text-xs text-gray-500"
        >
          {{ tip }}
        </div>
      </template>
      <div class="flex flex-col">
        <div class="">
          已经开启实名认证校验
        </div>
        <a-progress
          v-if="excelInfo.isShowProgress"
          class="w-4/5"
          :percent="excelInfo.realNameCheckPercent"
          status="active"
        />
      </div>

      <div v-if="realNameCheckFailTableInfo.dataSource.length">
        <div class="flex justify-between items-center">
          <div>
            检测结果：共导入{{ excelInfo.result.resultList.length }}条，<span class="text-green-500">{{ excelInfo.validatedNumber
            }}</span>条正常，<span class="text-red-500">{{ excelInfo.unValidateNumber }}</span>条异常
          </div>
          <div>
            <a-button
              type="primary"
              class="mr-3 "
              style="margin-top: 2px;"
              @click="toggle"
            >
              全屏预览
            </a-button>
            <a-button
              v-if="realNameCheckFailTableInfo.dataSource.length > 0"
              type="primary"
              class="ml-2 inline"
              style="margin-top: 2px;"
              @click="downloadError(realNameCheckFailTableInfo.dataSource)"
            >
              下载错误名单
            </a-button>
          </div>
        </div>
        <a-table
          ref="tableRef"
          :bordered="true"
          :scroll="{
            x: isFullscreen ? 'calc(100vh - 55px)' : tableWidth,
          }"
          :data-source="realNameCheckFailTableInfo.dataSource"
          :columns="realNameCheckFailTableInfo.columns"
          :pagination="{
            total: realNameCheckFailTableInfo.dataSource.length,
            pageSize: realNameCheckFailTableInfo.pageSize,
            showQuickJumper: true,
            showLessItems: true,
            onShowSizeChange: realNameCheckFailTableInfo.onShowSizeChange,
            showSizeChanger: true
          }"
        >
          <template #bodyCell="{ column, record }">
            <div :class="column.dataIndex === 'reason' ? 'text-red-500' : 'text-black'">
              {{ record[column.dataIndex] }}
            </div>
          </template>
        </a-table>
      </div>
      <div
        v-if="excelInfo.isComplete && excelInfo.unValidateNumber === 0"
        class="text-green-500"
      >
        所有人员已成功导入
      </div>
    </a-form-item-rest>
  </div>
</template>
<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { downloadFile } from '@/utils/file'
import { exportExcelDetail, getExcelOriginCell, getSheetHeaderAndData } from '@/utils/xlsx'
import { TableColumnsType, Divider, UploadFile } from 'ant-design-vue'
import { formatToDateTime, formatToDate, formatToMonth, dateFromNow } from '@/utils/date'
import { DateFormat, FilterDependedKeyAndString, sqcheckExcelImportProps } from './props'
import { cloneDeep, isArray, isEmpty, omit } from 'lodash-es'
import { Table } from 'ant-design-vue/es'
import { getRandomName } from './common/random-text-key'
import { useAppStore } from '@/store/modules/app'
import { catchData } from '../excel-import/common/catchData'
import { IRealNameCheckFailTableInfo } from '../excel-import/excel'
import { sqApi } from '@/api/sq.ts'
import { toErrorPackage } from '../excel-import/common/toErrorPackage'
import { IcheckedExcelInfo } from './types'
import { log } from 'console'
const { getReferenceItemsLabelMap } = useAppStore()


type EmitEvents = {
  (e: 'update:value', params: IcheckedExcelInfo['result']): void,
  (e: 'complete'): void,
}
const realNameCheckFailTableInfo = ref<IRealNameCheckFailTableInfo>({
  dataSource: [],
  onShowSizeChange(current: number, pageSize: number) {
    realNameCheckFailTableInfo.value.pageSize = pageSize
  },
  pageSize: 15,
  columns: []
})
const excelInfo = ref<IcheckedExcelInfo>({
  result: {
    isAllImport: false,
    resultList: [], // 最后需要传递给form的数据
  },
  isShowProgress: false,
  unValidateNumber: 0,
  validatedNumber: 0,
  realNameCheckPercent: 0,
  isComplete: false
})

const calibrator = () => {
  console.log('测试函数有没有被执行')
  realNameCheckFailTableInfo.value.dataSource = [] // 重置校验错误数据，防止重复提交数据重复
  let count = 0
  if (excelInfo.value?.result?.resultList.length === 0) {
    useMessage.error('通过校验的数据为空,请重新上传表格')
    return
  }
  const tempResultList = excelInfo.value?.result?.resultList
  excelInfo.value.isShowProgress = true
  sqApi.sqImportAnswerersCheck({
    sqAnswerers: tempResultList
  }).then(successRes => {
    console.log('successRes', successRes)
    if (isArray(successRes?.data?.data)) {
      realNameCheckFailTableInfo.value.dataSource = successRes?.data?.data?.filter(filterItem => filterItem?.error?.length > 0)?.map((item, index) => {
        excelInfo.value.unValidateNumber++
        // 如果返回的error数组长度大于1，就拼接字符串
        let errData = item?.error?.length > 1 ? item?.error?.join(',') : item?.error[0]
        const findItem = tempResultList.find(findItem => findItem.mobile === item.mobile)
        const dataSourceItem = Object.assign({ reason: toErrorPackage(errData) }, findItem)
        return dataSourceItem
      })
      excelInfo.value.validatedNumber = tempResultList?.length - realNameCheckFailTableInfo.value.dataSource?.length
    }
  })
    .catch(err => {
      useMessage.error(err)
    })
    .finally(() => {
      excelInfo.value.realNameCheckPercent = Math.min(Math.round(excelInfo.value.validatedNumber / tempResultList.length * 100), 100)
      excelInfo.value.result.isAllImport = realNameCheckFailTableInfo.value.dataSource?.length <= 0
      if (realNameCheckFailTableInfo.value.dataSource?.length <= 0) {
        excelInfo.value.isComplete = true
        emits('complete')
      }
    })

}
const fileList = ref<UploadFile[]>([])
const dataSource = ref<Recordable[]>([])
let errorList = ref<Recordable[]>([])
const columns = ref<TableColumnsType>([])
const tableRef = ref<InstanceType<typeof Table>>()
const tableWidth = ref(0)
const emits = defineEmits<EmitEvents>()
const props = defineProps(sqcheckExcelImportProps)
const { toggle, isFullscreen } = useFullscreen(tableRef)
const downloadError = async dataList => {
  let textKeyMaps = Object.keys(props.textKeyMap).map(key => ({ [key]: props.textKeyMap[key] }))
  textKeyMaps.unshift({ 失败原因: 'reason' })
  await exportExcelDetail(textKeyMaps, dataList, `${props.excelName}信息有误数据表.xlsx`)
}

/** 解决百分比化小数精度问题 （百分比最多三位小数时） */
const formatter = value => {
  let v = (parseFloat(value) / 100).toString()
  let vArr = v.split('.')
  if (vArr.length == 2) {
    v = `${vArr[0]}.${vArr[1].substring(0, 5)}`
    v = `${parseFloat(v)}`
  } else if (vArr.length == 1) {
    return `${v}`
  }
  return v
}

// 重置表格数据
const resetTable = () => {
  fileList.value = []
  dataSource.value = []
  excelInfo.value.result.resultList = []
  emits('update:value', unref(excelInfo).result)
}
// 格式化日期
const formatDate = (needFormatDataKey: string[], format: DateFormat, cloneItem) => {
  const formatFunctionMap = {
    'YYYY-MM-DD HH:mm': formatToDateTime,
    'YYYY-MM-DD': formatToDate,
    'YYYY-MM': formatToMonth,
    NOW: dateFromNow
  }
  needFormatDataKey.forEach(key => {
    cloneItem[key] = cloneItem[key] && formatFunctionMap[format](cloneItem[key])
  })
  return cloneItem
}
// 去百分号
const removePercentSign = (cloneDataList, needRemovePercentKey: string[], cloneItem, cloneItemIndex) => {
  needRemovePercentKey.forEach(key => {
    cloneItem[key] = cloneItem[key] && formatter(cloneItem[key])
  })
  return cloneDataList
}

// 过滤数据
const dataFilter = (cloneDataList, filterDependedKeyAndString: FilterDependedKeyAndString) => cloneDataList.filter(item => item[filterDependedKeyAndString.key] === filterDependedKeyAndString.dependedString)
async function handleChange({ file }) {

  let unValidateNumber = [] as Recordable[]
  if (file.status === 'removed') {
    resetTable()
    return
  }
  // 单个sheet导入
  const singleSheet = sheetList => {
    let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[0], props.textKeyMap)
    const headerColumnsTextKey = headerColumns.map(i => i.title)
    const textKey = Object.keys(props.textKeyMap)

    /* 处理除配置以外的表头数据 生成随机的字段用于展示*/
    const excessTextKey = getRandomName(headerColumnsTextKey.length - textKey.length)
    console.log(excessTextKey, '随机key')
    excessTextKey.forEach((key, index) => {
      headerColumns[textKey.length + index].dataIndex = key
    })
    dataList = dataList.filter(item => !isEmpty(item)).map(i => {
      let excessKeyAndValue: { key: string, value: string, label: string }[] = []
      let excessKeyAndValue1 = {}
      let needOmitKey: string[] = []
      excessTextKey.forEach((key, index) => {
        needOmitKey.push(headerColumns[textKey.length + index].title!)
        excessKeyAndValue1[headerColumns[textKey.length + index].title!] = i[headerColumns[textKey.length + index].title!]
        excessKeyAndValue.push({
          label: headerColumns[textKey.length + index].title!,
          value: i[headerColumns[textKey.length + index].title!],
          key: headerColumns[textKey.length + index].dataIndex!
        })
        i[headerColumns[textKey.length + index].dataIndex!] = i[headerColumns[textKey.length + index].title!]
      })
      if (!isEmpty(excessKeyAndValue1)) {
        i.feildList = excessKeyAndValue1
      }
      return omit(i, ...needOmitKey)
    })

    let status = false
    // 单sheet导入通过判断配置的表头名字来验证是否选错导入表格(为了可以自定义表头导入，只要选择的表头包含了配置的表头既正确)
    const inequalityArr = textKey.filter(key => !headerColumnsTextKey.includes(key))
    if (inequalityArr.length === 0 && textKey.length === headerColumnsTextKey.length) {
      status = true
    }
    let errorAndOriginalDataList: Recordable[] = []
    let tempUnValidateNumber: number[] = []
    let cloneDataList = cloneDeep(dataList)
    cloneDataList.forEach((item, index) => {
      // 是否去掉百分号
      if (props.needRemovePercentKey.length) {
        dataList[index] = removePercentSign(cloneDataList, props.needRemovePercentKey, item, index)
      }
      // 是否格式化日期
      if (props.needFormatDataKey.length) {
        dataList[index] = formatDate(props.needFormatDataKey, props.format, item)
      }

    })
    unValidateNumber = Array.from(new Set(tempUnValidateNumber)) // 去除重复索引
    // 过滤出没通过校验的数据
    let unValiDateDataList = errorAndOriginalDataList.filter((item, index) => unValidateNumber.includes(index))
    //  过滤出通过校验的数据
    let valiDateDataList = cloneDataList.filter((item, index) => !unValidateNumber.includes(index))
    console.log(valiDateDataList, '通过校验的数据', unValiDateDataList, '错误的数据')

    // 是否过滤数据
    if (props.isFilter) {
      valiDateDataList = dataFilter(valiDateDataList, props.filterDependedKeyAndString!)
    }
    return {
      headerColumns,
      errorAndOriginalDataList,
      valiDateDataList,
      unValiDateDataList,
      status
    }
  }


  const { excelData: sheetList, sheetNames } = await getExcelOriginCell(file) // 获取 excel 表所有 sheet 所有单元格数据
  // 多sheet导入时计算所有sheet的数据总数，单sheet导入只计算第一个sheet的数据长度
  if (props.maxImportNum + 1 < sheetList[0].length) {
    useMessage.error(`最多导入${props.maxImportNum}条`)
    resetTable()
    return
  }

  const { headerColumns, unValiDateDataList, errorAndOriginalDataList, valiDateDataList, status } = singleSheet(sheetList)
  if (!status) {
    useMessage.error('模板错误，请选择正确模板')
    return
  }
  console.log('headerColumns', headerColumns) // table 表头结构
  console.log('errorAndOriginalDataList', errorAndOriginalDataList) // table 表格数据
  console.log('valiDateDataList', valiDateDataList) // 传递给后端的数据
  tableWidth.value = headerColumns.length * 110
  columns.value = headerColumns
  const temp = [...headerColumns]
  temp.unshift({
    dataIndex: 'reason',
    title: '失败原因'
  })
  realNameCheckFailTableInfo.value.columns = temp
  dataSource.value = errorAndOriginalDataList
  errorList.value = unValiDateDataList
  excelInfo.value.result.resultList = valiDateDataList

  catchData(excelInfo.value.result.resultList, () => {
    fileList.value = [file]
    calibrator()
    emits('update:value', unref(excelInfo).result)
  })
}
// onMounted(() => {

//   /* 挂载好提交一次默认值，防止直接提交控制台报错（在form中会失去必填校验效果） */
//   emits('update:value', unref(excelInfo).resultList)
// })


</script>
