<template>
  <a-tree
    v-if="treeDataOptions.length"
    v-bind="bindAttrs"
  >
    <template #title="data">
      {{ fieldNames ? data[fieldNames?.title] : data?.title }}
      <check-square-outlined
        v-if="props.checkStrictly && data.children.length > 0 && allChecked(data.children)"
        @click.stop="removeAllChecked(data.children, fieldNames ? data[fieldNames?.key] : data?.key)"
      />
      <minus-square-outlined
        v-else-if="props.checkStrictly && data.children.length > 0"
        @click.stop="addAllChecked(data.children, fieldNames ? data[fieldNames?.key] : data?.key)"
      />
    </template>
  </a-tree>
</template>
<script lang="ts" setup>
import { TreeProps } from 'ant-design-vue'
import { DataNode } from 'ant-design-vue/es/tree'
import { isFunction } from 'lodash-es'
import { computed, onMounted, ref, useAttrs } from 'vue'
import { treeApiSelectProps, TreeApiSelectProps } from './props'
import { DownOutlined, SmileOutlined, FrownOutlined, FrownFilled, CheckOutlined, MinusSquareOutlined, CheckSquareOutlined } from '@ant-design/icons-vue'

// todo  组件bug fieldNames没传，内容会空白
const props = defineProps(treeApiSelectProps)
const attrs = useAttrs()
const { fieldNames } = attrs


type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void
}
const emits = defineEmits<EmitEvents>()
const treeDataOptions = ref<DataNode[]>([])
onMounted(() => {
  fetch()
})

const checkedIds = ref<any>([])

if (props.value) {
  checkedIds.value = props.value
}


const bindAttrs = computed((): TreeProps => ({
  ...attrs,
  treeData: treeDataOptions.value,
  checkable: true,
  selectable: false,
  checkStrictly: props.checkStrictly, // false onCheck v的格式['v'], true onCheck v格式：v: { checked: []，: Key[];}, options：CheckInfo
  checkedKeys: unref(checkedIds),
  // showIcon: true, // todo ture有乱码
  onCheck: (v: any, options) => {
    if (props.checkStrictly) {
      let checked = v.checked
      // if (options.checked) {
      //   if (options.node.children) {
      //     checked = checkedItem(checked, options.node.children)
      //   }
      // } else {
      //   checked = cancelCheckedItem(checked, options.node.children)
      // }
      checkedIds.value = checked
      emits('update:value', checked)
    } else {
      checkedIds.value = v
      emits('update:value', v)
    }
  }
}))

// onchange 勾选 不用
// function checkedItem(arr, children): string[] {
//   let checkeds: string[] = arr
//   children.forEach(item => {
//     checkeds.push(item.id)
//     if (item.children.length > 0) {
//       checkedItem(checkeds, item.children)
//     }
//   })
//   return checkeds
// }

// 取消勾选
// function cancelCheckedItem(arr, children): string[] {
//   let checkeds: string[] = arr
//   checkedIds.value
//   for (let i = 0; i < checkeds.length; i++) {
//     let item = checkeds[i]
//     children.forEach(child => {
//       if (child.id === item) {
//         checkeds.splice(i, 1)
//         i--
//       }
//       if (child.children.length > 0) {
//         cancelCheckedItem(checkeds, child.children)
//       }
//     })
//   }
//   return checkeds
// }

function addAllChecked(arr, parentId) {
  if (checkedIds.value.findIndex(c => c.id === parentId) === -1) {
    checkedIds.value.push(parentId)
  }
  arr.forEach(item => {
    if (checkedIds.value.findIndex(c => c == item.id) === -1) {
      checkedIds.value.push(item.id)
    }
    if (item.children.length > 0) {
      addAllChecked(item.children, parentId)
    }
  })
}

function removeAllChecked(arr, parentId) {
  let fieldIndex = checkedIds.value.findIndex(c => c === parentId)
  if (fieldIndex !== -1) {
    checkedIds.value.splice(fieldIndex, 1)
  }


  arr.forEach(item => {
    let index = checkedIds.value.findIndex(c => c == item.id)
    if (index !== -1) {
      checkedIds.value.splice(index, 1)
    }
    if (item.children.length > 0) {
      removeAllChecked(item.children, parentId)
    }
  })
}


function allChecked(arr) {
  let reuslt = []
  let list = allCheckedItems(reuslt, arr)
  // console.log(list)
  return list.length > 0 && list.every(item => item)
}

function allCheckedItems(result, arr) {
  let flag
  flag = arr.every(item => checkedIds.value.findIndex(c => c == item.id) > -1)
  result.push(flag)
  arr.forEach(item => {
    if (item.children.length > 0) {
      allCheckedItems(result, item.children)
    }
  })
  return result
}


async function fetch() {
  const { api, apiParams, valueToString } = props
  if (!api && !isFunction(api)) {
    return
  }
  let result = await api(apiParams)
  if (valueToString && attrs?.fieldNames !== undefined) {
    transforms(result, attrs?.fieldNames?.key)
  }
  treeDataOptions.value = result
}

function transforms(arr, valueKey) {
  arr.forEach(item => {
    item[valueKey] = item[valueKey].toString()
    if (item.children) {
      transforms(item.children, valueKey)
    } else {
      delete item.children
    }
  })
}
</script>
