import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const paymentRoutes: CustomRouteRecordRaw = {
  path: 'payment',
  name: 'payment',
  component: EmptyLayout,
  redirect: { name: 'paymentLog' },
  meta: {
    title: '资产中心',
    iconfont: 'icon-money-cny-box-line',
    requiredPermission: 'PAYMENT'
  },
  children: [
    {
      path: 'payment-log',
      name: 'paymentLog',
      component: () => import('@/views/payment/payment-log.vue'),
      meta: {
        title: '流水记录',
        requiredPermission: 'PAYMENT_LOG'
      }
    }
  ],
}
