import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const sqRoutes: CustomRouteRecordRaw = {
  path: 'sq',
  name: 'sq',
  component: EmptyLayout,
  meta: {
    title: '职业价值观测试',
    iconfont: 'icon-flask-line',
    requiredPermission: 'SQ_TEST'
  },
  children: [
    {
      path: 'sq-test-pay',
      name: 'sqTestPay',
      component: () => import('@/views/sq/sq-test-pay.vue'),
      meta: {
        title: '测评下单',
        requiredPermission: 'SQ_TEST_PAY'
      },
    },
    {
      path: 'sq-pay-log',
      name: 'sqPayLog',
      component: () => import('@/views/sq/sq-pay-log.vue'),
      meta: {
        title: '下单记录',
        requiredPermission: 'SQ_PAY_LOG'
      },
    },
    {
      path: 'sq-test-list',
      name: 'sqTestList',
      component: () => import('@/views/sq/sq-test-list.vue'),
      meta: {
        title: '测评记录',
        requiredPermission: 'SQ_TEST_LIST'
      },
    },
    {
      path: 'sq-test-report',
      name: 'sq-test-report',
      component: () => import('@/views/sq/sq-test-report.vue'),
      meta: {
        title: '报告列表',
        requiredPermission: 'SQ_TEST_REPORT'
      },
    },
  ],
}